@import "../../assets/variables";
@import "../../assets/mixins";

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  // flex-grow: 1;
  // display: flex;
  // flex-direction: column;

  &__tab-list {
    padding: 0;
    margin: 0;
    margin: 0.5rem $sidebar-padding 0 $sidebar-padding !important;
    display: flex;
    justify-content: space-between;
    background: var(--light-1);
    border-radius: $primary-border-radius;
    > * {
      flex: 1;
      text-align: center;
    }
  }

  &__tab {
    @include smallcaps();
    display: inline-block;
    list-style: none;
    position: relative;
    padding: 0.75rem 1rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 3px solid transparent;
    margin: 0.3rem;

    &:focus {
      text-decoration: underline;
    }
    &--selected {
      background: var(--accent);
      color: black;
      border-bottom-color: var(--accent);
      border-radius: $primary-border-radius;
    }

    &--disabled {
      cursor: default;
    }
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    display: none;

    &--selected {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
}
