@import "../../assets/variables";
@import "../../assets/mixins";

.pane {
  margin: 0;
  flex-direction: row;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.paneDiv {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px; /* Add this line */
  }

  p {
    font-size: 0.7rem;
    margin-top: 4px; /* Ensure there's a 4px gap */
  }
}

.editor {
  flex-grow: 1;
  max-height: 50vw;
  > div {
    padding: $sidebar-padding;
    background: var(--light-1);
    border-radius: $primary-border-radius;
    border: 1px solid transparent;
    overflow-y: scroll;
    min-height: 15vh;
    max-height: 25vw;
    &:focus {
      border-color: var(--accent);
      box-shadow: 0 0 0 3px var(--accent-transparent);
    }
  }
}

.wpmContainer {
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 1rem;
    align-items: center;
    display: flex;
    margin: 0;
  }
}

.wpmNumber {
  margin-left: 1rem;
  font-weight: 300;
  font-size: 2rem;
}

.words {
  border-radius: $primary-border-radius;
  border: 1px solid transparent;
  background: $color-light-1;
  color: $color-light-3;
  white-space: pre-wrap;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  min-height: 168px;
  line-height: 1.5;
  margin-top: 1em;
  padding: 1em;
  &:focus {
    border-color: var(--accent);
    box-shadow: 0 0 0 3px var(--accent-transparent);
  }
}

.timer {
  font-size: 14px;
  p {
    margin: 0;
    &:last-child {
      color: $color-light-2;
      margin-top: 0.5em;
    }
  }
}

.charAccent {
  &:first-letter {
    color: var(--accent);
  }
}

.abc {
  color: red;
}
