@import "../../assets/variables";

.card {
  min-width: 5em;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: $primary-border-radius;
  &:after {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: $primary-border-radius;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25),
      inset 0 2px 2px rgba(255, 255, 2555, 0.15);
    z-index: 1;
  }
  &:focus {
    text-decoration: underline;
    border-color: var(--accent);
    box-shadow: 0 0 0 3px var(--accent-transparent);
  }
}

.cardSwatch {
  overflow: hidden;
  min-width: 5em;
  &:focus {
    text-decoration: underline;
  }
}

.swatch {
  width: 100%;
  height: $primary-field-height + 10;
  background: rgba(white, 0.1);
  cursor: pointer;
}
.details {
  padding: 0.5rem;
  font-size: 14px;
  text-align: center;
  background: var(--light-1);
}

.dialogInline {
  position: relative;
  background: 0;
  border: none;
  margin: 0;
  width: 100%;
  padding: 0;
  div:after {
    display: none;
  }
}

.dialog {
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
  left: $sidebar-width + $sidebar-padding;
  width: $sidebar-width - (2 * $sidebar-padding);
  position: fixed;
  background: 0;
  border: none;
  margin: 0;
  padding: 0;
}

.dialogContainer {
  width: 100%;
  z-index: 5;
  max-width: 300px;
  padding: 0.75em;
  position: relative;
  border-radius: 5px;
  background: #42424a;
  filter: drop - shadow(0 0 10px rgba(0, 0, 0, 0.5));

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-70%, -50%);
    border-width: 20px 20px 20px 0;
    border-color: transparent #42424a transparent transparent;
  }
  input:focus {
    border-color: var(--accent) !important;
    box-shadow: 0 0 0 3px var(--accent-transparent);
  }
}

.colorSwatch {
  flex: 1;
  width: 54px;
  height: 24px;
  color: white;
  cursor: pointer;
  min-width: 50px;
  font-size: 12px;
  padding: 5px 7px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.9), 0 0 1px rgba(0, 0, 0, 0.5);
  &:hover,
  &:focus {
    z-index: 2;
    transform: scale(1.3);
    transform-origin: center;
    outline: 1px solid white;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
  }
}
