@import "../../assets/variables";
@import "../../assets/mixins";

.placeholder {
  width: 100%;
  margin: 5px 0;
  border-radius: 0.25em;
  border: 2px dashed rgba(white, 0.3);
}

.group {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.listLabel {
  @include smallcaps();
  width: 100%;
  padding: 0.75em 0;
  color: var(--light-2);
  justify-content: center;
  position: relative;
  display: flex;
  span {
    background: var(--main);
    position: relative;
    padding: 0 10px;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    top: calc(50% - 1px);
    background: $color-light-15;
    position: absolute;
    height: 1px;
    width: 100%;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  height: 100%; // Adjust this if you have a specific height in mind
}

// .list {
//   overflow-x: hidden;
//   overflow-y: auto; /* Make the list scrollable */
//   justify-content: start;
//   align-items: start;
// }

.list {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // padding: 0;
  // margin: 0;
  margin-right: 10px;
  list-style-type: none;
  max-height: 500px;
}

.list::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
  margin-left: 8px;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--light-2); /* Scrollbar color */
  border-radius: 4px; /* Rounded scrollbar */
}
