@import "../../assets/variables";

@import "../../assets/mixins";

.field {
  position: relative;
  margin-bottom: 1em;
  display: flex;
  input {
    margin: 0;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
}

.fieldColor {
  @extend .field;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  label {
    margin: 0;
    color: var(--light-3);
    margin-bottom: 1em;
  }
  > div {
    min-width: 60px;
  }
  margin-bottom: 1em;
  + .fieldColor {
    margin-left: 1em;
  }
}

.fieldCheck {
  @extend .field;
  margin: 1em 0;
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    > div:first-child {
      min-width: $control-width;
    }
  }
}
.fieldInfo {
  flex-grow: 1;
}

.fieldHelp {
  font-size: 90%;
  line-height: 1.4;
  margin-top: 0.5em;
  color: var(--light-1);
}

.group {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  border-radius: $primary-border-radius;
  justify-content: center;
  background: var(--light-1);
}
